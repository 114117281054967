
import { useNavigate } from "react-router-dom";
import { isJwtExpired, timeToExpire } from '../components/authentication/checkJWT';
import {postData,getDataWithBody, getData} from './auxilarFunctions';
import config from '../config.json';
import data from "@iconify/icons-eva/menu-2-fill";

let initial_state = {
  generalAB: {
      total: 1960,
      total_lastmonth: 70,
      total_lastmonthyear: 150,
      othertotal: 2130,
      othertotal_lastmonth: 140,
      average: 1264,
      perc_12_month: 27,
      otherperc_12_month: 34,
      perc_12_month_3_days: 15,
      perc_resistance_ab: 56,
     otherperc_resistance_ab: 38
  },
  consultations:{
    all: 7500,
    otherall: 9500,
    ab: 3750,
    otherab: 4750
  },
  prescriptions:{
    all: 8402,
    otherall: 7402,
    ab: 3699,
    otherab: 5699
  },
  ab: {
    practice_green: 1000,
    practice_green_lastmonth: 60,
    practice_green_lastmonthyear: 130,
    otherpractice_green: 1130,
    otherpractice_green_lastmonth: 120,
    practice_red: 960,
    practice_red_lastmonth:10,
    practice_red_lastmonthyear: 20,
    otherpractice_red: 1000,
    otherpractice_red_lastmonth: 20,
    hospital_green:1250,
    hospital_red: 150,
    total_12_month: 0,
    mostApear: ["Doxycycline"],
    resistace: 47,
    types_prescribed: [{
      x: 'Amoxicillin',
      y: 40,
      fillColor: "#26734D", 
      strokeColor: "#26734D"
    }, {
      x: 'Doxycycline',
      y: 4,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Ciprofloxacin',
      y: 10,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Penicillin V',
      y: 5,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Azithromyzin',
      y: 3,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Co-amoxiclav', 
      y: 3,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Cephalexin',
      y: 35,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    } ],
    green_prescribed: [{
      x: 'Amoxicillin',
      y: 40,
      fillColor: "#26734D", 
      strokeColor: "#26734D"
    }, {
      x: 'Doxycycline',
      y: 4,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Ciprofloxacin',
      y: 10,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Penicillin V',
      y: 5,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Azithromyzin',
      y: 3,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Co-amoxiclav', 
      y: 3,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Cephalexin',
      y: 35,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    } ],
    red_prescribed: [{
      x: 'Amoxicillin',
      y: 40,
      fillColor: "#26734D", 
      strokeColor: "#26734D"
    }, {
      x: 'Doxycycline',
      y: 4,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Ciprofloxacin',
      y: 10,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Penicillin V',
      y: 5,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Azithromyzin',
      y: 3,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Co-amoxiclav', 
      y: 3,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Cephalexin',
      y: 35,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    } ],
    types_resistance: [{
      x: 'Amoxicillin',
      y: 60,
      fillColor: "#26734D", 
      strokeColor: "#26734D"
    }, {
      x: 'Trimethoprim',
      y: 5,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Co-Amoxyclav',
      y: 12,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Nitrofurantoin',
      y: 23,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Cephalosporin',
      y: 5,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Quinolones', 
      y: 38,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    },],
    green_resistance: [{
      x: 'Amoxicillin',
      y: 60,
      fillColor: "#26734D", 
      strokeColor: "#26734D"
    }, {
      x: 'Trimethoprim',
      y: 5,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Co-Amoxyclav',
      y: 12,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Nitrofurantoin',
      y: 23,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Cephalosporin',
      y: 5,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Quinolones', 
      y: 38,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    },],
    red_resistance: [{
      x: 'Amoxicillin',
      y: 60,
      fillColor: "#26734D", 
      strokeColor: "#26734D"
    }, {
      x: 'Trimethoprim',
      y: 5,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Co-Amoxyclav',
      y: 12,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Nitrofurantoin',
      y: 23,
      fillColor: '#26734D',
      strokeColor: '#26734D'
    }, {
      x: 'Cephalosporin',
      y: 5,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    }, {
      x: 'Quinolones', 
      y: 38,
      fillColor: '#cc3311',
      strokeColor: '#cc3311'
    },],
    othertypes_resistance_red: [{
    name: 'Ciprofloxacin',
    data: [93, 100]
    }, 
    {
      name: 'Azithromyzin',
      data: [29, 55]
    }, 
    {
      name: 'Co-amoxiclav', 
      data: [79, 15]
    } ],  
    othertypes_red: [{
    name: 'Ciprofloxacin',
    data: [93, 121]
    }, 
    {
      name: 'Azithromyzin',
      data: [15, 45]
    }, 
    {
      name: 'Co-amoxiclav', 
      data: [59, 35]
    }],  

    othertypes_green: [{
      name: 'Amoxicillin',
      data: [135, 56],
    }, 
    {
      name: 'Doxycycline',
      data: [85, 110],
     }, 
    {
      name: 'Penicillin V',
      data: [23, 0],
    }, 
    {
      name: 'Cephalexin',
      data: [64, 86],
    } ],  

    othertypes_resistance_green: [{
      name: 'Amoxicillin',
      data: [100, 56],
    }, 
    {
      name: 'Doxycycline',
      data: [85, 100],
     }, 
    {
      name: 'Penicillin V',
      data: [23, 0],
    }, 
    {
      name: 'Cephalexin',
      data: [64, 86],
    } ], 
  },
  
  age: {
    green: [6653,8133, 3445, 2176, 2070, 3465, 10300],
    red: [353,133, 500, 700, 200, 650, 980],
    othergreen: [5653,9133, 5445, 3176, 1070, 1465, 7300],
    otherred: [153,33, 800, 300, 100, 850, 1080],
    categories: [
      '<1', 
      '1-6',
      '7-17',
      '18-29',
      '30-49',
      '50-69',
      '>=70'
    ]
  },
  
   
  time:{
  green_weekday : [653,833, 564, 455, 760],
  othergreen_weekday : [553,933, 664, 255, 460],
  red_weekday : [353,133, 56, 87, 156],
  otherred_weekday : [453,233, 156, 187, 56],
  green_duration: [36,53,68,13,4,87,20],
  othergreen_duration: [60,40,15,15,0,55,15],
  red_duration: [10,30,55,15,8,55,5],
  otherred_duration: [15, 20, 55, 0, 10, 15, 0],
  previousYear: [],
  greenRed: [],
  },

  year:{
  now: 2021,
  mid: 2020,
  last: 2019,
  green_2021:[280, 290, 330, 150, 200, 133, 33, 67, 111, 187, 230, 255],
  red_2021: [120, 110, 140, 180, 170, 130, 13, 55, 55, 69, 112, 198],
  green_2019:[240, 210, 300, 190, 230, 173, 39, 32, 141, 147, 200, 195],
  red_2019: [160, 140, 100, 210, 120, 160, 10, 90, 96, 45, 132, 218],
  green_2020: [180, 190, 130, 50, 20, 33, 33, 67, 11, 87, 30, 55],
  red_2020: [20, 10, 14, 80, 70, 30, 13, 55, 55, 69, 12, 98],
  }

}

// count model
export const antibiotics = {
  state: initial_state, // initial state
  reducers: {

    updateYearData(state,payload) {

      const { now, mid, last } = payload[0].ab_prescriptions;
      const zero = [0,0,0,0,0,0,0,0,0,0,0,0]

      return {
          ...state,
          year: {
            now:payload[0].ab_prescriptions.now,
            mid:payload[0].ab_prescriptions.mid,
            last:payload[0].ab_prescriptions.last,
            green_2021: payload[0].ab_prescriptions.data[now] ? (payload[0].ab_prescriptions.data[now].Green ? payload[0].ab_prescriptions.data[now].Green : zero) : zero,
            red_2021: payload[0].ab_prescriptions.data[now] ? (payload[0].ab_prescriptions.data[now].Red ? payload[0].ab_prescriptions.data[now].Red : zero) : zero,
            green_2020: payload[0].ab_prescriptions.data[mid] ? (payload[0].ab_prescriptions.data[mid].Green ? payload[0].ab_prescriptions.data[mid].Green : zero) : zero,
            red_2020: payload[0].ab_prescriptions.data[mid] ? (payload[0].ab_prescriptions.data[mid].Red ? payload[0].ab_prescriptions.data[mid].Red : zero) : zero,
            green_2019: payload[0].ab_prescriptions.data[last] ? (payload[0].ab_prescriptions.data[last].Green ? payload[0].ab_prescriptions.data[last].Green : zero) : zero,
            red_2019: payload[0].ab_prescriptions.data[last] ? (payload[0].ab_prescriptions.data[last].Red ? payload[0].ab_prescriptions.data[last].Red : zero) : zero,
          }
        }
    },

    updateGeneralAB2(state,payload) {
      return {
          ...state,
          generalAB: {
            total: payload.ab_prescribed ? payload.ab_prescribed : 0,
            average: payload.ab_percentage ? payload.ab_percentage : 0,
            perc_12_month: payload.percentage_ab ? payload.percentage_ab : 0,
            perc_12_month_3_days: payload.percentage_ab_longer3 ? payload.percentage_ab_longer3 : 0,
            perc_resistance_ab: 56,
            total_lastmonth: 70,
            total_lastmonthyear: 150,
            othertotal: 2130,
            othertotal_lastmonth: 140,
          }
        }
    },

    updateConsultationsprescriptions(state,payload) {
      return {
          ...state,
          consultations:{
            all: payload.cons_presc_ab.all_consultations ? payload.cons_presc_ab.all_consultations : 0,
            ab: payload.cons_presc_ab.ab_consultations ? payload.cons_presc_ab.ab_consultations : 0,
            otherperc_12_month: 34,
            otherperc_resistance_ab: 38
          },
          prescriptions:{
            all: payload.cons_presc_ab.all_prescriptions ? payload.cons_presc_ab.all_prescriptions : 0,
            ab: payload.cons_presc_ab.ab_prescriptions ? payload.cons_presc_ab.ab_prescriptions : 0,
            otherall: 7402,
            otherab: 5699
          },
        }
    },

    updateABPrescripons(state,payload) {
      const typesPrescribed = [];
      let practiceGreenTotal = 0;
      let practiceRedTotal = 0;
      let otherPracticeGreenTotal = 0;
      let otherPracticeRedTotal = 0;
      const abPrescriptions = payload[1]?.ab_prescriptions || [];
      let prescribedGreenData = [];
      let prescribedRedData = [];

      if (abPrescriptions.length >= 1) {
        for (let i = 0; i < abPrescriptions.length && i < 10; i++) {
          if (
            abPrescriptions[i].level_4 &&
            abPrescriptions[i].level_4 !== undefined
          ) {
            let fillColor, strokeColor;
            if (
              ["Penicillin", "Trimethoprim", "Nitrofurantoin"].includes(
                abPrescriptions[i].level_4
              )
            ) {
              fillColor = "#26734D";
              strokeColor = "#26734D";
              prescribedGreenData.push({
                x: abPrescriptions[i].level_4,
                y:
                  (parseInt(abPrescriptions[i].total) /
                    payload[2].ab_prescribed) *
                  100,
                fillColor: fillColor,
                strokeColor: strokeColor,
              });
            } else if (
              [
                "Penicillins with beta-lactam inhibitors",
                "Cephalosporin",
                "Quinolones",
                "Tetracyclines",
                "Macrolides",
              ].includes(abPrescriptions[i].level_4)
            ) {
              fillColor = "#cc3311";
              strokeColor = "#cc3311";
              prescribedRedData.push({
                x: abPrescriptions[i].level_4,
                y:
                  (parseInt(abPrescriptions[i].total) /
                    payload[2].ab_prescribed) *
                  100,
                fillColor: fillColor,
                strokeColor: strokeColor,
              });
            }

            typesPrescribed.push({
              x: abPrescriptions[i].level_4,
              y:
                (parseInt(abPrescriptions[i].total) /
                  payload[2].ab_prescribed) *
                100,
              fillColor: fillColor,
              strokeColor: strokeColor,
            });
          }
        }
      } else {
        prescribedRedData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        prescribedGreenData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        typesPrescribed.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
      }

      for (const item of payload[0].ab_prescriptions[1]) {
          if (item.ab_classification === "Green") {
              practiceGreenTotal += parseInt(item.total);
          }
          if (item.ab_classification === "Red") {
            practiceRedTotal += parseInt(item.total);
          }
      }

      for (const item of payload[0].ab_prescriptions[0]) {
          if (item.ab_classification === "Green") {
              otherPracticeGreenTotal += parseInt(item.total);
          }
          if (item.ab_classification === "Red") {
            otherPracticeRedTotal += parseInt(item.total);
          }
      }

      return {
          ...state,
          ab: {

            ...state.ab,
            practice_green: practiceGreenTotal,
            practice_green_lastmonth: 60,
            practice_green_lastmonthyear: 130,
            otherpractice_green: otherPracticeGreenTotal,
            otherpractice_green_lastmonth: 120,
            practice_red: practiceRedTotal,
            practice_red_lastmonth:10,
            practice_red_lastmonthyear: 20,
            otherpractice_red: otherPracticeRedTotal,
            otherpractice_red_lastmonth: 20,
            hospital_green:1250,
            hospital_red: 150,
            total_12_month: payload[2].ab_prescribed,
            all: payload[2].ab_prescribed,
            
            types_prescribed: typesPrescribed,
            red_prescribed: prescribedRedData,
            green_prescribed: prescribedGreenData,
              othertypes_resistance_red: [{
                name: 'Ciprofloxacin',
                data: [93, 100]
                }, 
                {
                  name: 'Azithromyzin',
                  data: [29, 55]
                }, 
                {
                  name: 'Co-amoxiclav', 
                  data: [79, 15]
                } ],  
                othertypes_red: [{
                name: 'Ciprofloxacin',
                data: [93, 121]
                }, 
                {
                  name: 'Azithromyzin',
                  data: [15, 45]
                }, 
                {
                  name: 'Co-amoxiclav', 
                  data: [59, 35]
                }],  
            
                othertypes_green: [{
                  name: 'Amoxicillin',
                  data: [135, 56],
                }, 
                {
                  name: 'Doxycycline',
                  data: [85, 110],
                 }, 
                {
                  name: 'Penicillin V',
                  data: [23, 0],
                }, 
                {
                  name: 'Cephalexin',
                  data: [64, 86],
                } ],  
            
                othertypes_resistance_green: [{
                  name: 'Amoxicillin',
                  data: [100, 56],
                }, 
                {
                  name: 'Doxycycline',
                  data: [85, 100],
                 }, 
                {
                  name: 'Penicillin V',
                  data: [23, 0],
                }, 
                {
                  name: 'Cephalexin',
                  data: [64, 86],
                } ], 
              
          },
        }
    },
    updateGeneralAB(state, payload){

      const zero = [0,0,0,0,0,0,0]

      return {
        ...state,
        age: {
          green: payload[0].ab_prescriptions ? ( payload[0].ab_prescriptions.Green ? payload[0].ab_prescriptions.Green : zero) : zero,
          red: payload[0].ab_prescriptions ? ( payload[0].ab_prescriptions.Red ? payload[0].ab_prescriptions.Red : zero) : zero,
          othergreen: [5653,9133, 5445, 3176, 1070, 1465, 7300],
          otherred: [153,33, 800, 300, 100, 850, 1080],
          categories: [
            '<1', 
            '1-6',
            '7-17',
            '18-29',
            '30-49',
            '50-69',
            '>=70'
          ]
        },
        // AppLineAB
        time:{
          green_weekday : payload[1].ab_prescriptions_by_dow.Green,
          othergreen_weekday : [553,933, 664, 255, 460],
          red_weekday : payload[1].ab_prescriptions_by_dow.Red,
          otherred_weekday : [453,233, 156, 187, 56],
          green_duration: payload[1].ab_prescriptions_by_duration.Green,
          othergreen_duration: [60,40,15,15,0,55,15],
          red_duration: payload[1].ab_prescriptions_by_duration.Red,
          otherred_duration: [15, 20, 55, 0, 10, 15, 0],
          previousYear: [],
          greenRed: [],
        }
      }
    },
    updateResistance(state,payload) {
      const { count } = payload.resistants || [];
      // Extract antibiotic names and their corresponding counts
      const data = count ? Object.entries(count).map(([name, count]) => ({ name, count })) : [];
      // Sort the data based on antibiotic name
      data.sort((a, b) => a.name.localeCompare(b.name));

      // Prepare data for rendering
      let resistanceData = [];
      let resistanceGreenData = [];
      let resistanceRedData = [];

      if (data.length >= 1) {
        resistanceData = data.slice(0, 10).map(({ name, count }, key) => {
          let fillColor, strokeColor;
          if (
            name === "Amoxicillin" ||
            name === "Trimethoprim" ||
            name === "Nitrofurantoin"
          ) {
            fillColor = strokeColor = "#26734D";
            resistanceGreenData.push({
              x: name,
              y: count,
              fillColor,
              strokeColor,
            });
          } else if (
            name === "Co-Amoxyclav" ||
            name === "Cephalosporin" ||
            name === "Quinolones"
          ) {
            fillColor = strokeColor = "#cc3311";
            resistanceRedData.push({
              x: name,
              y: count,
              fillColor,
              strokeColor,
            });
          } else {
            fillColor = strokeColor = key % 2 === 0 ? "#26734D" : "#cc3311";
            key % 2 === 0 ?  resistanceGreenData.push({
              x: name,
              y: count,
              fillColor,
              strokeColor,
            }) :  resistanceRedData.push({
              x: name,
              y: count,
              fillColor,
              strokeColor,
            });
          }
          return {
            x: name,
            y: count,
            fillColor,
            strokeColor,
          };
        });
      } else {
        resistanceRedData.push({
          x: '',
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        resistanceGreenData.push({
          x: '',
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
        resistanceData.push({
          x: "",
          y: 0,
          fillColor: "#26734D",
          strokeColor: "#26734D",
        });
      }


      return {
          ...state,
          ab: {
            ...state.ab,
            mostApear: payload.resistants ? ( payload.resistants.mostAppeard ? payload.resistants.mostAppeard : '') : '',
            resistace: payload.resistants ? ( payload.resistants.resistance ? payload.resistants.resistance : '0') : '0',
            resistanceTotal: payload.resistants ? ( payload.resistants.resistanceTotal ? payload.resistants.resistanceTotal : '0') : '0',
            types_resistance: resistanceData,
            red_resistance: resistanceRedData,
            green_resistance: resistanceGreenData,
          },
      }
    },
  },
   
  effects: (dispatch) => ({
     
    async getGeneralAB(payload, state) {

      try {
        const promise_1 =await postData(config.url +'/charts/antibiotics1', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updateGeneralAB2(promise_1); 
      } catch (error) {
        return error;
      }
    },

    async promice_8(payload, state){

      try {
        const promise_8 =await postData(config.url +'/charts/antibiotics8', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        
        return await this.updateResistance(promise_8); 
      } catch (error) {
        return error;
      }
    },

    async promice_2(payload, state){

      try {
        const promise_2 =await postData(config.url +'/charts/antibiotics2', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        return await this.updateConsultationsprescriptions(promise_2);
      } catch (error) {
        return error;
      }
    },

    async promice_3_4(payload, state){

      try {
        const promise_3 =await postData(config.url +'/charts/antibiotics3', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start: payload.payload ? payload.payload.end1 : state.filters.dateStart, end: payload.payload ? payload.payload.start1 : state.filters.dateEnd},state.user.access_token)
  
        const promise_4 =await postData(config.url +'/charts/antibiotics4', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start: payload.payload ? payload.payload.end1 : state.filters.dateStart, end: payload.payload ? payload.payload.start1 : state.filters.dateEnd},state.user.access_token)
        
        return await Promise.all([promise_3, promise_4,{ab_prescribed : state.antibiotics.generalAB.total}]).then(data => {
          this.updateABPrescripons(data)
        });
      } catch (error) {
        return error;
      }
    },

    async promice_5_6(payload, state){

      try {
        let ageValues = state.antibiotics.age.categories;
        const ageRange = state.filters.age
        let ageIndex= null
        ageIndex = ageRange.length > 0 ? ageRange.map(value =>{
                      return ageValues.indexOf(value[0]);
                    }) 
                    :null
  
        const promise_5 =await postData(config.url +'/charts/antibiotics5', {gms:state.filters.gms, age:ageIndex, age2:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        
        const promise_6 =await postData(config.url +'/charts/antibiotics6', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        
        return await Promise.all([promise_5, promise_6]).then(data => {
           this.updateGeneralAB(data)
      
        });
      } catch (error) {
        return error;
      }
    },

    async promice_7(payload, state){

      try {
        const promise_7 =await postData(config.url +'/charts/antibiotics7', {gms:state.filters.gms, age:state.filters.age, gender:state.filters.gender,start:state.filters.dateStart, end:state.filters.dateEnd},state.user.access_token)
        
        return Promise.all([promise_7]).then(data => {
           this.updateYearData(data)
      
        });
      } catch (error) {
        return error;
      }
    },
    
  })
}


// export const antibiotics = {
//     state: initial_state,

//     reducers: {
//       updateGeneralAB(state, payload) {

//         let indexes_ages_filters =  []
//         initial_state.age.categories.forEach(((cat, index)=>{
//           if(payload.all_state.filters.age.includes(cat)) {
//             indexes_ages_filters.push(index);
//           }
//         })) 

//         if(indexes_ages_filters.length == 0) {
//           indexes_ages_filters=[0,1,2,3,4,5,6]
//         }

//         console.log("indexes_ages_filters", indexes_ages_filters)
//         return {
//           ...state,
//           generalAB: {
//             total: Math.round(initial_state.generalAB.total*payload.percentage),
//             average: Math.round(initial_state.generalAB.average*payload.percentage),
//             perc_12_month: Math.round(initial_state.generalAB.perc_12_month*payload.percentage),
//             perc_12_month_3_days: Math.round(initial_state.generalAB.perc_12_month_3_days*payload.percentage),
//             perc_resistance_ab: Math.round(initial_state.generalAB.perc_resistance_ab*payload.percentage),
//           },
//           consultations:{
//             all: Math.round(initial_state.consultations.all*payload.percentage),
//             ab: Math.round(initial_state.consultations.ab*payload.percentage),
//           },
//           prescriptions:{
//             all: Math.round(initial_state.prescriptions.all*payload.percentage),
//             ab: Math.round(initial_state.prescriptions.ab*payload.percentage),
//           },
//           ab: {
//             practice_green: Math.round(initial_state.ab.practice_green*payload.percentage),
//             practice_red: Math.round(initial_state.ab.practice_red*payload.percentage),
//             hospital_green: Math.round(initial_state.ab.hospital_green*payload.percentage),
//             hospital_red: Math.round(initial_state.ab.hospital_red*payload.percentage),
//             types_prescribed: initial_state.ab.types_prescribed.map(m=> {return {...m, y:Math.round(m.y*(payload.percentage))}}),
//             types_resistance: initial_state.ab.types_resistance.map(m=> {return {...m, y:Math.round(m.y*(payload.percentage))}}),
//             total_12_month: Math.round(initial_state.ab.total_12_month*payload.percentage),
//           },
//           age: {
//             green: initial_state.age.green.filter((cat, idx)=>indexes_ages_filters.includes(idx)).map(cat=>Math.round(cat*(payload.percentage))),
//             red: initial_state.age.red.filter((cat, idx)=>indexes_ages_filters.includes(idx)).map(cat=>Math.round(cat*(payload.percentage))),
//             categories: payload.all_state.filters.age.length > 0 ? payload.all_state.filters.age : initial_state.age.categories
//           },
//           time: {
//             green_weekday: initial_state.time.green_weekday.map(m=> Math.round(m*(payload.percentage))),
//             red_weekday: initial_state.time.red_weekday.map(m=> Math.round(m*(payload.percentage))),
//             green_duration: initial_state.time.green_duration.map(m=> Math.round(m*(payload.percentage))),
//             red_duration: initial_state.time.red_duration.map(m=> Math.round(m*(payload.percentage))),
//             green_2021: initial_state.time.green_2021.map(m=> Math.round(m*(payload.percentage))),
//             red_2021: initial_state.time.red_2021.map(m=> Math.round(m*(payload.percentage))),
//             green_2020: initial_state.time.green_2020.map(m=> Math.round(m*(payload.percentage))),
//             red_2020: initial_state.time.red_2020.map(m=> Math.round(m*(payload.percentage))),
            
//           }

//         }
//     }

//     },
//     effects: (dispatch) => ({
//       getGeneralAB(payload, state) {
//         console.log("state", state)
//         let filters = 0;
//         let total_filters = 0;
//         if(state.filters.age.length > 0){
//           filters = filters + state.filters.age.length
//           total_filters = total_filters + 7
//         }
//         if(state.filters.gender.length > 0){
//           filters = filters + state.filters.gender.length
//           total_filters = total_filters + 3
//         }
//         if(state.filters.gms.length > 0){
//           filters = filters + state.filters.gms.length
//           total_filters = total_filters + 2
//         }
//         if (filters > 0 && total_filters > 0) {
//           dispatch.antibiotics.updateGeneralAB({percentage: filters/total_filters, all_state: state });
//         } else {
//           dispatch.antibiotics.updateGeneralAB({percentage: 1 , all_state: state});

//         }
//       }
//     })
// };