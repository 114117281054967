import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader, Grid, Stack, Typography } from "@mui/material";
// utils
import { fNumber, addToMap } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";
import { alpha, styled } from "@mui/material/styles";
import { shadows } from "@mui/system";
import React, { useState, useEffect } from "react";

// ----------------------------------------------------------------------

function AppConversionRates(props) {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [CHART_DATA_RED, setChartDataRed] = useState([]);
  const [CHART_DATA_GREEN, setChartDataGreen] = useState([]);

  useEffect(() => {
    let consolidatedRedMap = new Map();
    let map = addToMap(props.ab.red_prescribed, 0, consolidatedRedMap);
    map = addToMap(props.otherab.othertypes_red, 1, map);
    const result = Array.from(map.entries()).map(([name, data]) => ({
      name,
      data,
    }));
    const total = result.reduce((sum, item) => sum + (item.data[0] || 0), 0);
    const filteredResult = result.map((item) => ({
      ...item,
      percentage: ((item.data[0] || 0) / total) * 100,
    }));
    const sortedResult = filteredResult.sort((a, b) => b.data[0] - a.data[0]);
    setChartDataRed(sortedResult);
    setRefreshGraph((prev) => !prev);
  }, [props.ab.red_prescribed, props.otherab.othertypes_red]);

  useEffect(() => {
    let consolidatedGreenMap = new Map();
    let map = addToMap(props.ab.green_prescribed, 0, consolidatedGreenMap);
    map = addToMap(props.otherab.othertypes_green, 1, map);
    const result = Array.from(map.entries()).map(([name, data]) => ({
      name,
      data,
    }));
    const total = result.reduce((sum, item) => sum + (item.data[0] || 0), 0);
    const filteredResult = result.map((item) => ({
      ...item,
      percentage: ((item.data[0] || 0) / total) * 100,
    }));
    const sortedResult = filteredResult.sort((a, b) => b.data[0] - a.data[0]);
    setChartDataGreen(sortedResult);
    setRefreshGraph((prev) => !prev);
  }, [props.ab.green_prescribed, props.otherab.othertypes_green]);
  console.log('CHART_DATA_GREEN');
  console.log('CHART_DATA_GREEN',CHART_DATA_GREEN);
  console.log('CHART_DATA_RED',CHART_DATA_RED);

  const chartOptionsTypes = merge(
    {},
    {
      tooltip: {
        enabled: false,
        marker: { show: false },
        x: {
          show: true,
          // format: 'dd MMM',
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        y: {
          //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
          formatter: function (value, opts) {
            let percent =
              opts.w.globals.seriesPercent[opts.seriesIndex][
                opts.dataPointIndex
              ];
            return percent.toFixed(0) + "%";
          },
          title: {
            formatter: (seriesName) => seriesName + ":",
          },
        },
      },
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        height: 250,
      },
      colors: [
        "#26734D",
        alpha("#26734D", 0.7),
        alpha("#26734D", 0.6),
        alpha("#26734D", 0.5),
        alpha("#26734D", 0.4),
      ],

      stroke: {
        show: true,
        colors: "#cc3311",
        width: 10,
      },
      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          console.log('Green',w.config.series[seriesIndex].name)
          let percent = w.globals.seriesPercent[seriesIndex][dataPointIndex];
          if (percent > 4) {
            return (
              w.config.series[seriesIndex].name?.split(" ")[0] +
              ":  " +
              percent.toFixed(0) +
              "%"
            );
          } else {
            return "";
          }
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "60%",
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return w.config.series[seriesIndex].name + ":  " + value;
            },
          },
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: "category",
        categories: ["Your practice", "Filtered practices"],
        labels: {
          show: true,
        },
      },
      yaxis: {
        // max: 150,
        // tickAmount: 1,
        labels: {
          show: false,
          style: {
            colors: "#22577A",
          },
        },
      },
      grid: {
        show: false,
      },
    }
  );

  const chartOptionsTypes_RED = merge(
    {},
    {
      tooltip: {
        enabled: false,
        marker: { show: false },
        x: {
          show: false,
          // format: 'dd MMM',
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        y: {
          //formatter: (seriesName) => 'TOTAL'/100*fNumber(seriesName),
          formatter: function (value, opts) {
            let percent =
              opts.w.globals.seriesPercent[opts.seriesIndex][
                opts.dataPointIndex
              ];
            return percent.toFixed(0) + "%";
          },
          title: {
            formatter: (seriesName) => seriesName + ":",
          },
        },
      },
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        height: 250,
      },
      colors: [
        "#cc3311",
        alpha("#cc3311", 0.7),
        alpha("#cc3311", 0.6),
        alpha("#cc3311", 0.5),
        alpha("#cc3311", 0.4),
      ],

      stroke: {
        show: true,
        colors: "#cc3311",
        width: 10,
      },
      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          console.log('Red',w.config.series[seriesIndex].name)
          let percent = w.globals.seriesPercent[seriesIndex][dataPointIndex];
          if (percent > 4) {
            return (
              w.config.series[seriesIndex].name?.split(" ")[0] +
              ":  " +
              percent.toFixed(0) +
              "%"
            );
          } else {
            return "";
          }
        },
        textAnchor: "middle",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "60%",
          horizontal: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: "category",
        categories: ["Your practice", "Filtered practices"],
        labels: {
          show: true,
        },
      },
      yaxis: {
        // max: 150,
        // tickAmount: 1,
        labels: {
          show: false,
          style: {
            colors: "#22577A",
          },
        },
      },
      grid: {
        show: false,
      },
    }
  );

  return (
    <Grid container spacing={4} justifyContent="space-around">
      <Grid item sm={12} lg={6}>
        <Box sx={{ mx: 1, width: 500, height: 400 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_GREEN}
            options={chartOptionsTypes}
            key={refreshGraph}
          />
        </Box>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Box sx={{ mx: 1, width: 500, height: 400 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={CHART_DATA_RED}
            options={chartOptionsTypes_RED}
            key={refreshGraph}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
import { connect } from "react-redux";
const mapState = (state) => ({
  ab: state.antibiotics.ab,
  otherab: state.antibioticscomparisons.abcomparison,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
