// material
import { Box, Grid, Container, Typography, Paper, Stack, Button } from '@mui/material';
// components
import Page from '../components/Page';
import { useState, useEffect } from "react";
import {
  AppTypesOfAntibiotics,
  ApptotalAB,
  ApptotalLabresults,
  ApptotalConsult,
  ApptotalAge,
  AppLineAB,
  AppConsultPrescribe,
  AppAgeOverview
} from '../components/_dashboard/antibiotics/';
import ReactGA from 'react-ga4';

// ----------------------------------------------------------------------


let data = {
  ab: {
    total: 1960,
    average: 1264
  },
  categories: [
    '<1',
    '1-6',
    '6-12',
    '12-24',
    '24-50',
    '50-65',
    '65-70',
    'over 70'
  ],
  consultations: {
    '12_month': {
      all: 3000,
      ab_perc: 45, // %
      ab_total: 1960
    }
  }
}
export default function DashboardApp() {

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Antibiotic Dashboard 3" });



    ReactGA.send({
      hitType: "event",  // Instead of "pageview", we use "event" for custom tracking
      eventCategory: "Pageview_AB 3",  // Define your own category
      eventAction: "Pageview_AB 3",   // Or "pageview2" depending on your needs
      eventLabel: window.location.pathname,  // The current path
      title: "Antibiotic Dashboard 3",  // Keep the title if necessary
    });
  }, []);


  return (
    <Page title="CARA | Antibiotic Dashboad" sx={{ backgroundColor: '#fff' }}>
      <Container maxWidth="xl" >
        <Box sx={{ pb: 5 }}>

        </Box>
        <Grid container spacing={12}>
          <Grid item xs={3} sm={6} md={12}>
            <Typography variant="h2" color='secondary'>Antibiotics</Typography>
            <Typography variant="subtitle1" color='secondary'>Graphs display data of selected duration to the last upload.</Typography>
            <br />
            <Button variant="contained" href=''>Upload new data</Button>
            <br></br>
          </Grid>

          <Grid item xs={12} sm={6} md={4} >
            <ApptotalAB data={data} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <ApptotalAge />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <ApptotalLabresults />
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ borderRadius: 0 }}>
          <Grid item xs={12} md={6} lg={12}>
            <AppConsultPrescribe />
          </Grid>

          <Grid item xs={12} md={6} lg={12} id="antibiotics">
            <AppTypesOfAntibiotics />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppAgeOverview />
          </Grid>

          <Grid item xs={12} md={6} lg={12} id="duration">
            <AppLineAB />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}
